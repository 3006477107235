@font-face {
    font-family: gtsuper;
    src: url('../assets/fonts/GT-Super-Display-Light-Trial.otf');
  }

.trackspage{
    height:fit-content;
    width:100%;
    overflow-x:hidden;
    /* background-image: linear-gradient(#C99F79,#F6DEBA); */
    /* padding-bottom: 105vh; */
    background-color: #f2edea;
}

.tracks_heading{
    font-family: gtsuper;
    color: #000000;
    font-size:80px;
    text-align: center;
    /* text-shadow: 4px 6px #00000024; */
    /* padding-top: 16vh; */
    /* padding-left: 6vw; */
    padding-top: 12vh;
}


.gridContainer {
    min-width: 100%;
    justify-items: center;
    justify-content: space-around;
    align-items: center;
    padding-top: 3vw;
    display: grid;
    grid-template-columns: 300px 300px 300px 300px; 
    grid-gap: 70px; /* Adjust the gap between items */
    padding: 2vw;
}  

@media screen and (max-width: 480px){

    .gridContainer {
        grid-template-columns: repeat(auto-fit, minmax(340px, 300px)); 
        /* padding:.5vw; */
    }
    .tracks_heading{
        font-size:80px;
        text-align: center;
        padding-left: 0vw;
    }
}


@media screen and (max-width: 350px){

    .gridContainer {
        grid-template-columns: repeat(auto-fit, minmax(300px, 300px)); 
    }
}