.eventpage {
    width: 100%;
    min-height: 100%;
    /* background-color: aquamarine ; */
    padding-bottom: 5vh;
    background-color: #d2bfad;
}

.eventimage img {
    max-height: 500px;
    min-width: 100%;
    background-repeat: no-repeat;
    object-fit: cover;
    border-radius: 0 0 20px 20px;
}

.eventtitle {
    font-family: gtpro;
    position: absolute;
    top: 20%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 5rem;
    color: white;
}