@font-face {
    font-family: gtsuper;
    src: url('../assets/fonts/GT-Super-Display-Light-Trial.otf');
  }

  .pasticketspage{
    overflow-x: hidden;
    overflow-y: hidden;
    max-width: 100%;
    padding-bottom:10vh;
  }

.upcoming{
    /* padding: 20px; */
    position: relative;
    top: 12vh;
    justify-content: center;
    /* display: flex; */
    /* left: 47.5%; */
    font-family: gtsuper;
    font-size: 3vw;
    text-align: center;
}

.gridContainer {
    min-width: 100%;
    justify-items: center;
    justify-content: space-around;
    align-items: center;
    padding-top: 3vw;
    display: grid;
    grid-template-columns: 300px 300px 300px 300px; 
    grid-gap: 10px; /* Adjust the gap between items */
    padding: 2vw;
    padding-top: 4vw;
    padding-bottom: 4vw;
}  