.form2 {
    width: 25vw;
    position: absolute;
    top: 7.5vw;
    left: 7vw;
}

.icon1 {
    position: absolute;
    top: 8.2vw;
    left: 2.8vw;
}

.form3 {
    width: 25vw;
    position: absolute;
    top: 12vw;
    left: 7vw;
}

.icon2 {
    position: absolute;
    top: 12.7vw;
    left: 2.8vw;
}

.form4 {
    width: 25vw;
    position: absolute;
    top: 16.5vw;
    left: 7vw;
}

.icon3 {
    position: absolute;
    top: 17.2vw;
    left: 2.8vw;
}

.login {

    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: #FFFFFF;
    border-radius: 5px;

}

input[type="text"] {
    border: 0;
    border-radius: 0;
    outline: 0;
    font-size: 1.5vw;
    color: black;
    background: transparent;
    border-bottom: 1px solid #c9a48e;
}

::-webkit-input-placeholder {
    font-size: 1.5vw !important;
    color: #B0B0B0;
}

:-moz-placeholder {
    font-size: 1.5vw !important;
    color: #B0B0B0;
}

::-moz-placeholder {
    font-size: 1.5vw !important;
    color: #B0B0B0;
}