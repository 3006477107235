.landingpage{
    width:100vw;
    height:100vh;
    background-color: #FCFBFA;
}

.snapfestlogo{
    position: absolute;
    top:0;
    left:0;
}

.footer{
    background-color:#c9a48e;font-family: Arial;font-size:1.1vw;bottom:0%;position:absolute;
    padding:1%;
    max-width: 100%;
    min-width: 98%;
}

.containerform{
    position:absolute;
    top:6vw;
    right:2vw;
}

@import url('https://fonts.googleapis.com/css2?family=La+Belle+Aurore&display=swap');

@font-face {
    font-family: gtsuper;
    src: url("../assets/fonts/GT-Super-Display-Light-Trial.otf");
}

.homesnapfest {
    width: 1000px;
    display: flex;
    z-index: 1;
}

.gradient {


    position: absolute;
    width: 544.65px;
    height: 0px;
    left: -46px;
    top: 30px;
    border: 183px solid #C9A48E;
    filter: blur(80.9px);
    transform: rotate(2deg);
    z-index: 0;
}



.snapfest {


    position: absolute;
    width: 972px;
    height: 152px;
    left: 32px;
    top: 59px;

    font-family: gtsuper;
    font-style: normal;
    font-weight: 300;
    font-size: 202px;
    line-height: 234px;
    letter-spacing: -0.04em;
    z-index: 1;
    color: #000000;
}

.moments {
    position: absolute;
    width: 792px;
    height: 71px;
    left: 58px;
    top: 279px;

    font-family: 'GT Super Ds Trial';
    font-style: italic;
    font-weight: 300;
    font-size: 41px;
    line-height: 47px;
    letter-spacing: 0.02em;

    color: #000000;
}

/* making moments magical! */
.magical {

    position: absolute;
    width: 792px;
    height: 71px;
    left: 359px;
    top: 286px;

    font-family: "La Belle Aurore", cursive;
    font-weight: 400;
    font-style: normal;
    font-size: 60px;
    line-height: 47px;
    letter-spacing: 0.02em;

    color: #000000;
}